<template>
	<div class="py-2">
		<div class="pb-1 px-4 header">
			<h1 class="crud-title">Website Themes</h1>
		</div>
		<v-row class="mx-0 pt-6 pb-3 px-4">
			<v-col
				cols="4"
				class="pt-0 d-flex"
				v-for="theme in themes"
				:key="theme.id"
			>
				<!-- <v-hover v-slot:default="{ hover }"> -->
				<v-card class="ma-0" width="100%">
					<div v-if="theme.image">
						<v-img
							:aspect-ratio="30 / 21"
							class="theme-img"
							:src="theme.image"
						>
							<!-- <v-expand-transition>
							<div
								v-if="hover"
								class="
									d-flex
									flex-column
									fab-transition
									grey
									darken-4
									v-card--reveal
									display-3
									white--text
								"
								style="height: 100%"
							>
								<v-btn
									depressed
									class="preview-btn mb-5 d-flex"
								>
									Preview<span
										class="mdi mdi-open-in-new pl-2"
									></span
								></v-btn>
								<v-btn
									outlined
									color="var(--blue)"
									class="apply-btn d-flex"
									@click="updateTheme(theme.id)"
								>
									Use Theme</v-btn
								>
							</div>
						</v-expand-transition> -->
						</v-img>
					</div>
					<div v-else>
						<v-img
							src="https://www.pngitem.com/pimgs/m/416-4162990_image-coming-soon-with-grey-camera-digital-camera.png"
						>
						</v-img>
					</div>

					<v-card-text class="my-4 pa-0">
						<h3 class="font-weight-light black--text text-center">
							{{ theme.name }}
						</h3>
					</v-card-text>

					<p class="text-center" v-if="!theme.image">Coming soon</p>
				</v-card>
				<!-- </v-hover> -->
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			themes: [
				{
					name: 'Classic',
					image: 'https://image1.jdomni.in/preview-theme/9/5/4/063e9563-b624-4889-ae6a-bc0a20e178c1.jpeg?output-format=webp',
				},
				{
					name: 'Modern',
				},
				{ name: 'Aesthetics' },
			],
		}
	},
	created() {
		this.axios({
			method: 'get',
			url: '/project/themes',
		}).then((res) => {
			this.themes = res.data.data
		})
	},
	methods: {
		updateTheme(themeId) {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'put',
				url: `/project/${projectId}/theme`,
				data: {
					themeId: themeId,
				},
			}).then((res) => {
				console.log(res)
			})
		},
	},
}
</script>

<style scoped>
.theme-img:hover {
	opacity: 1 !important;
}
.v-card--reveal {
	align-items: center;
	bottom: 0;
	justify-content: center;
	position: absolute;
	opacity: 0.85;
	width: 100%;
}
.preview-btn {
	color: white !important;
	background-color: var(--blue) !important;
	width: 60%;
}
.preview-btn .mdi {
	font-size: 1rem;
}
.apply-btn {
	background: white !important;
	width: 60%;
}
.header {
	border-bottom: 1px solid #eee;
}
</style>
